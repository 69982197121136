<template>
    <div class="author">
        <router-link
            v-if="link"
            :to="link"
            class="author__avatar"
        >
            <avatar :src="avatar"/>
        </router-link>

        <div
            v-else
            class="author__avatar"
        >
            <avatar :src="avatar"/>
        </div>

        <div class="author__meta">
            <router-link
                v-if="link"
                :to="link"
                class="author__fullname"
            >
                {{ fullName }}
            </router-link>

            <span
                v-else
                class="author__fullname"
            >
                {{ fullName }}
            </span>

            <span
                class="author__date"
                :key="timeKey"
                :title="$moment(dateOutput).format('LLL')"
            >
                {{ dateOutput.local().fromNow() }}
            </span>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Author',
        components: {
            Avatar: () => import('@/components/Avatar')
        },
        computed: {
            ...mapState('default_data', [
                'time'
            ])
        },
        props: {
            avatar: {
                type: String,
                required: false,
                default: ''
            },
            fullName: {
                type: String,
                required: true
            },
            link: {
                type: String,
                required: true
            },
            date: {
                type: String,
                required: false,
                default: ''
            },
            timeKey: {
                type: Number,
                required: false,
                default: new Date().getTime()
            }
        },
        data() {
            return {
                dateOutput: this.$moment.utc(this.date)
            };
        },
        mounted() {
            const statusDate = this.$moment.utc(this.date).local().valueOf() > new Date().getTime();
            this.dateOutput = statusDate ? this.$moment(this.date) : this.dateOutput;
        }
    };
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"

    .author
        color: #000
        display: flex
        align-items: flex-start
        &__meta
            display: flex
            flex-direction: column
            margin-left: 15px
        &__fullname
            color: unset
            font-size: 1rem
            font-weight: 600
        &__date
            font-size: 1rem
            color: $text-gray
</style>
